.form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #cdcdcd;
  height: 35px;
  margin-bottom: 10px;
  user-select: none;

  &.extended {
    margin-top: 35px;
  }

  & > label {
    font-family: "Roboto";
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.selected {
      font-family: "Roboto";
      font-weight: bold;
      color: #ce3f1b;
    }

    &:hover {
      color: #e94e36;
    }

    &:not(:last-of-type)::after {
      content: "";
      display: block;
      height: 52%;
      border-right: 1px solid #cdcdcd;
      position: absolute;
      top: calc(50% - 26%);
      left: 100%;
    }
  }

  input {
    display: none;
  }
}

.description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-family: "Roboto";

  & > span {
    color: #878787;
    font-size: 10px;
    text-align: center;
    width: 100%;
    padding: 0 4px;

    &.selected {
      font-family: "Roboto";
      font-weight: bold;
      color: black;
    }
  }
}
